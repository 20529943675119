import Api from '@/services/Index';

const getSlots = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/' + id);
}

const getCreditLimit = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/' + id + '/credit/limit')
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/registration', data);
}

const getElectiveCourses = async (id, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('course/registration/' + id + '/elective/courses', config);
}

const getChangeSection = async (studentProgramId, courseId, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('course/registration/sections/' + courseId + '/' + studentProgramId, config);
}

const putChangeSection = async (studentProgramId, courseId, sectionId, type) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('course/registration/section/' + courseId + '/' + studentProgramId, {'section_id': sectionId, 'type': type});
}

const timetables = async (sectionIds) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('sections/schedule', {section_ids: sectionIds});
}

const del = async (studentProgramId, slotId, courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('course/registration/' + slotId + '/' + courseId + '/' + studentProgramId);
}

const confirm = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.patch('course/registration/confirm', data);
}

const exportExcelOfConfirmatedStudents = ()=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/courses/confirmation-excel-export');
}

const getExchange = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/exchange/' + id);
}

const getExchangeCourseSections = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/exchange/sections/' + id);
}

const storeExchange = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/registration/exchange', data);
}

const delExchange = async (studentProgramId, slotId, courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('course/registration/exchange/' + slotId + '/' + courseId + '/' + studentProgramId);
}


export default {
    getSlots,
    getCreditLimit,
    store,
    getElectiveCourses,
    getChangeSection,
    putChangeSection,
    timetables,
    del,
    confirm,
    exportExcelOfConfirmatedStudents,
    getExchange,
    getExchangeCourseSections,
    storeExchange,
    delExchange,

}
